import { FallbackLoading } from "components/Fallback/Fallback";
import { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import UserNotfound from "components/Notfound/UserNotFound";
const Ulogin = lazy(() => import("pages/User/Login/Ulogin"))
const Ucheckout = lazy(() => import("pages/User/Checkout/Ucheckout"))
const Uregister = lazy(() => import("pages/User/Register/Uregister"))
const Uforgotpassword = lazy(() => import("pages/User/Forgotpass/Uforgotpass"))
const Ureset = lazy(() => import("pages/User/Reset/Ureset"))
const Udashboard = lazy(() => import("pages/User/Dashboard/Udashboard"))
const Uorder = lazy(() => import("pages/User/Order/Uorder"))
const Uorderlist = lazy(() => import("pages/User/OrderList/Uorderlist"))
const Uprofile = lazy(() => import("pages/User/Profile/Uprofile"))
const Uproductlist = lazy(() => import("pages/User/Products/Uproductlist"))
const Ucart = lazy(() => import("pages/User/Cart/Ucart"))
const Uproductdetails = lazy(() => import("pages/User/ProductDetails/Uproductdetails"))

const Userroute = () => {
  // const loc = useLocation()
  // const locationPath = ["login", "register"]
  // useEffect(() => {
  //   const locdata = locationPath.filter(l => loc.pathname.indexOf(l) > -1)
  //   if (locdata.length) {
  //     document.querySelector("body")?.classList.add("loginAuth")
  //   }else{
  //     document.querySelector("body")?.classList.remove("loginAuth")
  //   }
  // }, [])
  return (
    <Routes>
      <Route path="/" element={
        <Suspense fallback={<FallbackLoading />}>
          <Udashboard />
        </Suspense>} />
      <Route path="login" element={
        <Suspense fallback={<FallbackLoading />}>
          <Ulogin />
        </Suspense>} />
      <Route path="forgotpassword" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uforgotpassword />
        </Suspense>
      } />
      <Route path="reset" element={
        <Suspense fallback={<FallbackLoading />}>
          <Ureset />
        </Suspense>
      } />
      <Route path="register" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uregister />
        </Suspense>
      } />
      <Route path="dashboard" element={
        <Suspense fallback={<FallbackLoading />}>
          <Udashboard />
        </Suspense>
      } />
      <Route path="dashboard/cart" element={
        <Suspense fallback={<FallbackLoading />}>
          <Ucart />
        </Suspense>
      } />
      <Route path="dashboard/checkout" element={
        <Suspense fallback={<FallbackLoading />}>
          <Ucheckout />
        </Suspense>
      } />
      <Route path="dashboard/products" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uproductlist />
        </Suspense>
      } />
      <Route path="dashboard/products/detail/:id" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uproductdetails />
        </Suspense>
      } />
      <Route path="dashboard/profile" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uprofile />
        </Suspense>
      } />
      <Route path="dashboard/orders" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uorderlist />
        </Suspense>
      } />
      <Route path="dashboard/order/:id" element={
        <Suspense fallback={<FallbackLoading />}>
          <Uorder />
        </Suspense>
      } />
      <Route path="*" element={<UserNotfound />} />
    </Routes>
  );
}

export default Userroute;