import { Update, Delete } from "@mui/icons-material";
import { Avatar, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { AxiosError } from "axios";
import SnackbarAlert from "custom/components/SnackbarAlert";
import { IPropsError, IPropsUserFeedback } from "pages/Interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminLoginVerify } from "services/Adminservice";
import { userFeedbackData } from "services/Userservice";

const Afeedback = () => {
    const nav = useNavigate()
    const [snackopen, setSnackOpen] = useState<IPropsError>({ open: false, severity: undefined, message: "" })
    const [isLoading, setLoading] = useState<boolean>(false)
    const [userFeedback, setUserFeedback] = useState<IPropsUserFeedback[]>([])
    const [feedbackKey, setFeedbackKey] = useState<Array<any>>([]);

    useEffect(() => {
        (async function () {
            if (localStorage.getItem("admintoken")) {
                try {
                    setLoading(true)
                    const verify = await adminLoginVerify(String(localStorage.getItem("admintoken")));
                    if (verify.data.status === "Success") {
                        const { _id: adminId } = verify.data.data
                        const feedbackRes = await userFeedbackData()
                        if (feedbackRes.data.status === "Success") {
                            setUserFeedback(feedbackRes.data.feedbackData)
                            setFeedbackKey(Object.keys(feedbackRes.data.feedbackData[0]))
                            setLoading(false)
                        } else {
                            setSnackOpen({ open: true, severity: "warning", message: feedbackRes.data.message })
                            setLoading(false)
                        }
                    } else {
                        nav("/admin/login")
                    }
                } catch (error: unknown) {
                    setSnackOpen({ open: true, severity: "warning", message: (error as AxiosError).message })
                }
            } else {
                nav("/admin/login")
            }
        })();
    }, [])

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen({ open: false, severity: undefined, message: "" });
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Stack>
                <Stack spacing={2} direction={"column"} justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"}>
                    {
                        isLoading ? <div>Loading...</div> :
                            userFeedback.length ?
                                (<Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer >
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {feedbackKey?.map((plKey: keyof IPropsUserFeedback, i: number) => (
                                                        <TableCell key={i} align={"center"} sx={{ textTransform: "capitalize", bgcolor: "black", color: "white" }}>{plKey.replace(/([A-Z])/g, ' $1')}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userFeedback.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((feedbackList: IPropsUserFeedback) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={feedbackList._id}>
                                                            {feedbackKey?.map((plKey: keyof IPropsUserFeedback, i: number) => {
                                                                return (
                                                                    <TableCell key={i} align={"center"}>{feedbackList[plKey]}</TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        component="div"
                                        count={userFeedback.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>)
                                : <div>Product Not Found</div>
                    }
                </Stack>
            </Stack>
            {/* <SnackbarAlert snackopen={snackopen} setSnackOpen={setSnackOpen} /> */}
        </>
    )
}

export default Afeedback;