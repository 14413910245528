import { IPropsOrders } from "pages/Interface"
import axiosInstance from "./axiosInstance"

export const userRegister = async (phonetoken: string, data: object) => {
    return await axiosInstance.post(`/user/register/${phonetoken}`, data)
}

export const userLogin = async (data: object) => {
    return await axiosInstance.post('/user/login', data)
}

export const userLoginByOtp = async (token: string, data: object) => {
    return await axiosInstance.post(`/user/login/${token}`, data)
}

export const userLoginVerify = async (token: string) => {
    return await axiosInstance.get(`/user/dashboard/${token}`)
}

export const userGetAllProducts = async () => {
    return await axiosInstance.get(`/user/getproducts`)
}

export const userGetProduct = async (userId: string, id: string) => {
    return await axiosInstance.get(`/user/getproduct/${userId}/${id}`)
}

export const userGetProducts = async (userId: string) => {
    return await axiosInstance.get(`/user/getproducts/${userId}`)
}

export const userOTPVerify = async (phoneNumber: string, type?: string) => {
    return await axiosInstance.get(`/user/verifyotp/${phoneNumber}/${type}`)
}

export const userGetCategoryList = async () => {
    return await axiosInstance.get(`/user/productcategorylist`)
}

export const userAddOrDeleteWishList = async (userId: string, prodId: string) => {
    return await axiosInstance.get(`/user/addordeletewishlist/${userId}/${prodId}`)
}

export const userAddOrDeleteCart = async (datas: any) => {
    return await axiosInstance.post(`/user/addordeletecart`, datas)
}

export const userGetWishList = async (userId: string) => {
    return await axiosInstance.get(`/user/getwishlist/${userId}`)
}

export const userGetCart = async (userId: string) => {
    return await axiosInstance.get(`/user/getcart/${userId}`)
}

export const userProfileupdate = async (userId: string, data: object) => {
    return await axiosInstance.put(`/user/profileupdate/${userId}`, data)
}

export const userPostCheckOut = async (userId: string, data: object) => {
    return await axiosInstance.post(`/user/checkout/${userId}`, data)
}

export const userGetCheckOut = async (userId: string) => {
    return await axiosInstance.get(`/user/checkout/${userId}`)
}

export const userGetCountryList = async () => {
    return await axiosInstance.get(`https://restcountries.com/v3.1/all`)
}

export const userGetShippingAddress = async (userId: string) => {
    return await axiosInstance.get(`/user/shipping/${userId}`)
}

export const userPostShippingAddress = async (userId: string, data: object) => {
    return await axiosInstance.post(`/user/shipping/${userId}`, data)
}

export const userPaymentOrders = async (data: object) => {
    return await axiosInstance.post(`/user/payment/orders`, data)
}

export const userPaymentVerify = async (userId: string, data: object) => {
    return await axiosInstance.post(`/user/payment/verify/${userId}`, data)
}

export const userPaymentCOD = async (userId: string, data: object) => {
    return await axiosInstance.post(`/user/payment/cod/${userId}`, data)
}

export const userForgotPassword = async (token: String, data:any) => {
    return await axiosInstance.post(`/user/forgotpassword/${token}`, data)
}

export const userVerifyForgotPassword = async (forgottoken: string) => {
    return await axiosInstance.get(`/user/verifyforgotpassword/${forgottoken}`)
}

export const userUpdatePassword = async (data: object) => {
    return await axiosInstance.post(`/user/updatepassword/`, data)
}

export const userOrderList = async (userId: string) => {
    return await axiosInstance.get(`/user/orderlist/${userId}`)
}

export const userGetOrder = async (id: string) => {
    return await axiosInstance.get(`/user/getorder/${id}`)
}

export const userFeedback = async (data: any) => {
    return await axiosInstance.post(`/user/userfeedback`, data)
}

export const userFeedbackData = async () => {
    return await axiosInstance.get(`/user/userfeedback`)
}

export const userDownloadInvoice = async (data: IPropsOrders) => {
    return await axiosInstance.post(`/user/downloadinvoice`, { orderData: data }, {
        responseType: 'blob',
    })
}
