import Rootrouter from "router/Rootrouter";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css'

const App = () => {
  return (
    <>
      <Rootrouter/>
    </>
  );
}

export default App;