import axiosInstance from "./axiosInstance"

export const adminRegister = async (data: any) => {
    return await axiosInstance.post('/admin/register', data)
}

export const adminLogin = async (data: any) => {
    return await axiosInstance.post('/admin/login', data)
}

export const adminLoginVerify = async (admintoken: string) => {
    return await axiosInstance.get(`/admin/dashboard/${admintoken}`)
}

export const adminAddProduct = async (data: any) => {
    return await axiosInstance.post('/admin/addproduct', data)
}

export const adminGetProduct = async (id: any) => {
    return await axiosInstance.get(`/admin/getproducts/${id}`)
}

export const adminUpdateProductById = async (id: any, data: any) => {
    return await axiosInstance.post(`/admin/updateproduct/${id}`, data)
}

export const adminDeleteProductById = async (id: any) => {
    return await axiosInstance.delete(`/admin/deleteproduct/${id}`)
}

export const adminGetCategoryList = async () => {
    return await axiosInstance.get(`/admin/productcategory`)
}

export const adminGetOrders = async (adminId: string) => {
    return await axiosInstance.get(`/admin/getorders/${adminId}`)
}

export const adminGetUsers = async (adminId: string) => {
    return await axiosInstance.get(`/admin/getusers/${adminId}`)
}

export const adminConfirmOrder = async (adminId: string, email: string | undefined, val:string) => {
    return await axiosInstance.get(`/admin/confirmorder/${adminId}/${email}/${val}`)
}

export const adminAddCategory = async (categoryName: string) => {
    return await axiosInstance.get(`/admin/addcategory/${categoryName}`)
}

export const updateQTPProduct = async (id:string, data: any) => {
    return await axiosInstance.post(`/admin/updateqtpproduct/${id}`,data)
}