import { Stack, Typography } from "@mui/material"

const UserNotfound = () => {
    return (
        <Stack width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant={"h3"}>User Page 404 Not Found</Typography>
        </Stack>
    )
}

export default UserNotfound;