import { Routes, Route } from "react-router-dom";
import React, { Suspense } from 'react'
import AdminNotfound from "components/Notfound/AdminNotfound";
import { FallbackLoading } from "components/Fallback/Fallback";
import Afeedback from "pages/Admin/Feedback/Afeedback";
const Adashboard = React.lazy(() => import("pages/Admin/Dashboard/Adashboard"))
const Alogin = React.lazy(() => import("pages/Admin/Login/Alogin"))
const Aproduct = React.lazy(() => import("pages/Admin/Productlist/Aproduct"))
const Aregister = React.lazy(() => import("pages/Admin/Register/Aregister"))
const Sspanel = React.lazy(() => import("pages/Admin/Panel/Adminpanel"))
const Aorder = React.lazy(() => import("pages/Admin/Orderlist/Aorder"))
const Ausers = React.lazy(() => import("pages/Admin/Userlist/Ausers"))
const Profile = React.lazy(() => import("pages/Admin/Profile/Profile"))
const ForgotPassword = React.lazy(() => import("pages/Admin/Forgotpassword/AForgotpassword"))

const Adminroute = () => {
  return (
    <Routes>
      <Route path="/" element={
        <Suspense fallback={<FallbackLoading />}>
          <Alogin />
        </Suspense>
      } />
      <Route path="login" element={
        <Suspense fallback={<FallbackLoading />}>
          <Alogin />
        </Suspense>
      } />
      <Route path="register" element={
        <Suspense fallback={<FallbackLoading />}>
          <Aregister />
        </Suspense>
      } />
      <Route path="forgotpassword" element={
        <Suspense fallback={<FallbackLoading />}>
          <ForgotPassword />
        </Suspense>
      } />
      <Route path="dashboard" element={
        <Suspense fallback={<FallbackLoading />}>
          <Adashboard />
        </Suspense>} >
        <Route path="sspanel" element={
          <Suspense fallback={<FallbackLoading />}>
            <Sspanel />
          </Suspense>
        } />
        <Route path="product" element={
          <Suspense fallback={<FallbackLoading />}>
            <Aproduct />
          </Suspense>
        } />
        <Route path="orderlist" element={
          <Suspense fallback={<FallbackLoading />}>
            <Aorder />
          </Suspense>
        } />
        <Route path="userlist" element={
          <Suspense fallback={<FallbackLoading />}>
            <Ausers />
          </Suspense>
        } />
        <Route path="feedback" element={
          <Suspense fallback={<FallbackLoading />}>
            <Afeedback />
          </Suspense>
        } />
        <Route path="myprofile" element={
          <Suspense fallback={<FallbackLoading />}>
            <Profile />
          </Suspense>
        } />
      </Route>
      <Route path="*" element={<AdminNotfound />} />
    </Routes>
  );
}

export default Adminroute;