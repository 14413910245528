import { Routes, Route, HashRouter as Router } from "react-router-dom";
import Adminroute from "./Adminrouters/Adminroute";
import Userroute from "./Userrouters/Userroute";
import { Suspense, lazy } from "react";
import { FallbackLoading } from "components/Fallback/Fallback";
const Udashboard = lazy(() => import("pages/User/Dashboard/Udashboard"))
const Uhome = lazy(() => import("pages/User/Home/Uhome"))

const Rootrouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <Suspense fallback={<FallbackLoading />}>
            <Uhome />
          </Suspense>
        } />
        <Route path="/user/*" element={
          <Suspense fallback={<FallbackLoading />}>
            <Userroute />
          </Suspense>
        } />
        <Route path="/admin/*" element={
          <Suspense fallback={<FallbackLoading />}>
            <Adminroute />
          </Suspense>
        } />
      </Routes>
    </Router>
  );
}

export default Rootrouter;